import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

import {
  TextField,
  MenuSelectorField,
  ModelMenuSelectorField,
  SingleOptionSelectorField
} from '@front/squirtle/'

const Title = ({ title, ...props }, context) =>
  <Grid container>
    <Grid item xs>
      <Typography sx={{ fontSize: 'subtitle2.fontSize', color: 'primary.main' }}>{title}</Typography>
    </Grid>
  </Grid>

const Row1 = ({ isPackage, ...props }, context) => {

  return (
    <Grid container item xs spacing={1} rowSpacing={2} alignItems='flex-end' >
      <Grid item xs={5}>
        <TextField
          name='removal_period_duration'
          label={I18n.t('transport.removal_period_duration')}
          type='number'
          required={!!isPackage}
          emptyValue='-'
          InputProps={{
            style: { width: '60%' },
            inputProps: { style: { textAlign: 'right' } },
            endAdornment: <Box fontSize='subtitle2.fontSize' color='text.label' > {I18n.t('minute.label.short')} </Box>
          }}
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          name='delivery_period_duration'
          label={I18n.t('transport.delivery_period_duration')}
          type='number'
          required={!!isPackage}
          emptyValue='-'
          InputProps={{
            style: { width: '60%' },
            inputProps: { style: { textAlign: 'right' } },
            endAdornment: <Box fontSize='subtitle2.fontSize' color='text.label' > {I18n.t('minute.label.short')} </Box>
          }}
        />
      </Grid>
    </Grid>
  )
}

const Row2 = ({ to_be_grouped, isReadOnly, ...props }, context) => {

  return (
    <Grid container item xs spacing={1} rowSpacing={2} alignItems='flex-end'>
      <Grid item xs={4}>
        <TextField
          name='contract_id'
          label={I18n.t('contract.interface_id')}
          emptyValue='-'
          disabled={!to_be_grouped || !!isReadOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <SingleOptionSelectorField
          name='to_be_grouped'
          optionLabel={I18n.t('to_be_grouped')}
        />
      </Grid>
      <Grid item xs={3}>
        <SingleOptionSelectorField
          name='auto_calculate_payment'
          optionLabel={I18n.t('billing.auto_calculate_payment')}
        />
      </Grid>
    </Grid>
  )
}

const Row3 = ({ auto_calculate_payment, ...props }, context) => {

  return (
    <Grid container item xs spacing={2} rowSpacing={2} alignItems='flex-end'>
      <Grid item xs>
        <TextField
          name='jobcounter.flat_rate'
          label={I18n.t('rateparameter.flat_rate')}
          emptyValue='-'
          type='number'
          required={!!auto_calculate_payment}
          InputProps={{
            inputProps: { style: { textAlign: 'right' } },
            endAdornment: <Box fontSize='subtitle2.fontSize' color='text.label' ml={1} mr={1}> {I18n.t('fiat.eur.sign')} </Box>,
          }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='jobcounter.km_rate'
          label={I18n.t('rateparameter.km_rate')}
          type='number'
          required={!!auto_calculate_payment}
          InputProps={{
            inputProps: { style: { textAlign: 'right' } },
            endAdornment: <Box fontSize='subtitle2.fontSize' color='text.label' ml={1} mr={1}> {I18n.t('fiat.eur.sign')} </Box>,
          }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='jobcounter.pax_add_cost'
          label={I18n.t('jobcounter.pax_add_cost')}
          type='number'
          required={!!auto_calculate_payment}
          InputProps={{
            inputProps: { style: { textAlign: 'right' } },
            endAdornment: <Box fontSize='subtitle2.fontSize' color='text.label' ml={1} mr={1}> {I18n.t('fiat.eur.sign')} </Box>,
          }}
        />
      </Grid>
    </Grid>
  )
}



const SettingSection = (props) => {
  const { auto_calculate_payment, isPackage } = props
  return <>
    <StepLabel>
      <Typography variant='h5'>{I18n.t('order.grouped_and_package')}</Typography>
    </StepLabel>
    <StepContent >
      <Box sx={{ p: 5, boxShadow: 6, borderRadius: 3 }}>
        <Grid container rowSpacing={3} >
          <Grid item xs={12}>
            <Title title={I18n.t('load.package.label', { count: -1 })} {...props} />
            <Row1 {...props} />
          </Grid>
          <Grid item xs={12}>
            <Title title={I18n.t('order.grouped')} {...props} />
            <Row2 {...props} />
          </Grid>
          {!!auto_calculate_payment &&
            <Grid item xs={12}>
              <Row3 {...props} />
            </Grid>}
        </Grid>
      </Box>
    </StepContent>
  </>
}

export default React.memo(SettingSection)
