import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent, ImageList } from '@mui/material'

import {
  TextField,
  MenuSelectorField,
  ModelMenuSelectorField,
  SingleOptionSelectorField
} from '@front/squirtle/'

const Row1 = props => (
  <Grid container item xs={12} spacing={2} alignItems='flex-end'>
    <Grid item xs={5} >
      <TextField
        name={'name'}
        label={I18n.t('name.label', { count: 1 })}
        required
      />
    </Grid>

    <Grid item xs={2}>
      <MenuSelectorField
        label={I18n.t('load.label', { count: 1 })}
        name={'load_type'}
        selectFirst
        options={[
          { label: I18n.t('load.user.label', { count: -1 }), value: 'user' },
          { label: I18n.t('load.package.label', { count: -1 }), value: 'package' }
        ]}
        required
      />
    </Grid>
    <Grid item xs={3}>
      <ModelMenuSelectorField
        name={'application'}
        label={I18n.t('application.label.alter')}
        model_name='application'
        labelKeys={['name']}
        loadOnMount
        searchMode={'search'}
        required
      />
    </Grid>
  </Grid>
)

const Row2 = props => (
  <Grid container item xs={12} spacing={2} alignItems='flex-end'>
    <Grid item xs>
      <TextField
        name={'client_type_icon'}
        label={I18n.t('icon')}
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name='enabled'
        optionLabel={I18n.t('active')}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name='display_supervision'
        optionLabel={I18n.t('display.supervision')}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'display_index'}
        label={I18n.t('display.order')}
        type='number'
      />
    </Grid>
  </Grid>
)

const PrimarySection = props => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('info.general')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 5, boxShadow: 6, borderRadius: 3 }}>
      <Grid container rowSpacing={3}>
        <Row1 {...props} />
        <Row2 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
