import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box } from '@mui/material'

import { TextField, OptionSelectorField, ModelMenuSelectorField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('driver.search')} </Typography>
  </Grid>

const Row1 = ({ group_id, ...props }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField name='info.user_client_id' label={I18n.t('driver.code')} />
    </Grid>
    <Grid item xs>
      <TextField name='auth.gsm' label={I18n.t('phone.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name='info.last_name' label={I18n.t('name.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name='info.first_name' label={I18n.t('name.first_name', { count: 1 })} />
    </Grid>
    <Grid item xs hidden={!group_id}>
      <TextField name='contracts.group.group_client_id' label={I18n.t('group.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name='vehicle.info.plaque' label={I18n.t('vehicle.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name='driverinfo.capacity_certif' label={I18n.t('driver.capacity_certif')} />
    </Grid>
  </Grid>

const Row2 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'options'}
        label={I18n.t('options.useroption.label', { count: 2 })}
        model_name='useroption'
        labelKeys={['name_translated']}
        searchMode='search'
        allowNone={false}
        multiple
        loadOnMount
      />
    </Grid>
    <Grid item xs>
      <TextField name='auth.email' label={I18n.t('email.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <OptionSelectorField
        name='status'
        label={I18n.t('status.label', { count: 1 })}
        options={[
          { label: I18n.t('status.active'), value: 'active' },
          { label: I18n.t('status.deleted'), value: 'deleted' },
          { label: I18n.t('status.suspended'), value: 'suspended' }
        ]}
        multiple
      />
    </Grid>
    <Grid item xs>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <Typography variant='subtitle1' sx={{ mr: 1, width: '-webkit-fill-available' }}>{I18n.t('evaluation.interval')}</Typography>
        <TextField name='average_rating_start' type='number' />
        <Typography variant='subtitle1' sx={{ ml: 1, mr: 1 }}>{I18n.t('prefix.and')}</Typography>
        <TextField name='average_rating_end' type='number' />
        <Typography variant='subtitle1' sx={{ ml: 1, mr: 1 }}>{'/5'}</Typography>
      </Box>
    </Grid>
  </Grid >

const SearchForm = props =>
  <Grid container justifyContent='center'>
    <Grid item xs={10}>
      <Box sx={{ bordrRadius: 1, boxShadow: 6, p: 2 }}>
        <Grid container rowSpacing={3}>
          <Title />
          <Row1 />
          <Row2 />
        </Grid>
      </Box>
    </Grid>
  </Grid>

export default React.memo(SearchForm)
