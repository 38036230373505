import React, { useCallback } from 'react'

import { Button } from '@mui/material'
import {
  TableHeader,
  TableRow,
  FormTable,
  TableRowActions,
  TableDialogAction,
  ConfirmationDialog
} from '@front/squirtle'
import { hooks, I18n } from '@front/volcanion'

import Header from './Header'
import Row from './Row'

const PriceruleSection = ({ commercial_package_id, record_id, ...props }) => {
  const handleCreate = useCallback(() =>
    window.open(`/pricerule/create?commercialpackage_id=${commercial_package_id}`, '_blank')
    [commercial_package_id])
  const handleRowClick = useCallback((rowId, row) => window.open(`/pricerule/${_.get(row, 'pricerule.price_rule_id')}`), [])
  const [deletePricerule] = hooks.useModelFunction('commercialpackagepricerule', 'delete')
  return (
    <>
      <Button
        color='primary'
        size='large'
        sx={{ fontSize: 'subtitle.fontSize' }}
        variant='outlined'
        onClick={handleCreate}
      >
        {I18n.t('pricerule.add.short')}
      </Button>
      <ConfirmationDialog name={'pricerule_delete'} onConfirm={async (state) => await deletePricerule({ id: _.get(state, 'row.id') })} />
      <FormTable
        model_name={'commercialpackagepricerule'}
        config={{
          forced_filter: { commercialpackage: commercial_package_id, pricerule: { generic: true } },
          validate_filter: useCallback(() => !!commercial_package_id, [commercial_package_id]),
          watchers: [commercial_package_id],
          initial_filter: {},
          populate: [
            'pricerule.pricing',
            'pricerule.start_zone',
            'pricerule.end_zone',
            'commercialpackage'
          ]
        }}
        loadOnMount={!!record_id}
        tableProps={{
          enableRowActions: true,
          allowEditMode: false,
          allowRowGenerate: false,
          onRowClick: handleRowClick
        }}
      >
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>

        <TableRowActions>
          <TableDialogAction
            name="pricerule_delete"
            label={I18n.t('action.delete')}
            getDialogExtra={({ row }) => ({ title: I18n.t('commercialpackage.confirm_delete_pricerule', { name: _.get(row, 'pricerule.name') }) })}
          />
        </TableRowActions>
      </FormTable>
    </>
  )
}

export default React.memo(PriceruleSection)
