import React, { useState, useEffect} from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => ({record_id, ...rest}) => {
  const { isLoading, ...status} = hooks.useSearchListenerStatus('order', 'order_validation_list')
  
  const [openOrder, setOpenOrder] = useState(false)
  const [openBilling, setOpenBilling] = useState(true)
  const [openHistory, setOpenHistory] = useState(false)
  const [openMap, setOpenMap] = useState(false)

  useEffect(() => {
    if (!!record_id) {
      setOpenOrder(false)
      setOpenBilling(true)
      setOpenHistory(false)
      setOpenMap(false)
    } else {
      setOpenOrder(false)
      setOpenBilling(false)
      setOpenHistory(false)
      setOpenMap(false)
    }
  }, [record_id])

  const mergedProps = {
    isLoading,
    record_id,
    openOrder,
    openBilling,
    openMap,
    openHistory,
    setOpenHistory,
    setOpenMap,
    setOpenBilling,
    setOpenOrder,
  }

  return <Component {...mergedProps} />
}

export default withContainer
