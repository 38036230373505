import React, { useCallback } from 'react'
import { ModelForm, hooks } from '@front/volcanion'

const withForm = Component => props => {
  const { authorizedpackage_id } = hooks.useParams()

  const formProps = {
    name: 'authorizedpackage_form',
    model_name: 'authorizedpackages',
    record_id: authorizedpackage_id,
    populate: []
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
