import React from 'react'
import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

import { I18n } from '@front/volcanion/'

import {
  TextField,
  MenuSelectorField,
  SingleOptionSelectorField
} from '@front/squirtle/'

const Row1 = (props, context) => {

  return (
    <Grid container item xs={12} spacing={1} alignItems='flex-end'>
      <Grid item xs >
        <TextField
          name={'name'}
          label={I18n.t('name.label', { count: 1 })}
          required
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='authorized_phone_number'
          label={I18n.t('phone.authorized')}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='schedule_delay'
          label={I18n.t('commercial_formula.schedule_delay')}
          type='number'
          emptyValue='-'
          InputProps={{
            inputProps: { style: { textAlign: 'right' } }
          }}
          required
        />
      </Grid>
    </Grid>
  )
}

const Row2 = (props, context) => {

  return (
    <Grid container item xs={12} spacing={1} alignItems='flex-end'>
      <Grid item xs >
        <TextField
          name='label_for_call_center'
          label={I18n.t('display.operator.one')}
          required
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='label_for_driver'
          label={I18n.t('display.driver.one')}
          required
        />
      </Grid>
    </Grid>
  )
}

const Row3 = (props, context) => {

  return (
    <Grid container item xs={12} spacing={1} alignItems='flex-end'>
      <Grid item xs >
        <MenuSelectorField
          name='ivr_access'
          label={I18n.t('ivr.access')}
          options={
            [
              { label: I18n.t('ivr_access.never'), value: '0' },
              { label: I18n.t('ivr_access.yes_if_order'), value: '1' },
              { label: I18n.t('ivr_access.yes_without_limit'), value: '2' }
            ]}
          required
        />
      </Grid>
    </Grid>
  )
}
const Row4 = (props, context) => {

  return (
    <Grid container item xs={12} spacing={1} alignItems='flex-end'>
      <Grid item xs>
        <TextField
          name='dispatch_priority'
          label={I18n.t('priority.dispatching.other')}
          required
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='display_index'
          label={I18n.t('display.index')}
          type='number'
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='fixed_fee'
          label={I18n.t('bank.fixed_fee')}
          InputProps={{
            endAdornment: <Box> {I18n.t('fiat.eur.sign')} </Box>
          }}
          required
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='fee_rate'
          label={I18n.t('bank.fee_rate')}
          InputProps={{
            endAdornment: <Box> {I18n.t('prefix.percent')} </Box>
          }}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <SingleOptionSelectorField
          name='deferred_invoicing_or_inapp_invoice'
          label={I18n.t('commercial_formula.deferred_invoicing_or_inapp_invoice')}
        />
      </Grid>
      <Grid item xs={1}>
        <SingleOptionSelectorField
          name='is_given_priority_for_incident_processing'
          label={I18n.t('priority.incident.other')}
        />
      </Grid>
      <Grid item xs={1}>
        <SingleOptionSelectorField
          name='is_authorized'
          label={I18n.t('commercial_formula.is_authorized')}
        />
      </Grid>
    </Grid>
  )
}

const PrimarySection = (props) => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('info.general')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={3}>
        <Row1 {...props} />
        <Row2 {...props} />
        <Row3 {...props} />
        <Row4 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
