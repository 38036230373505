import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => ({ row, rowId, orders, handleBillingAction, ...props }) => {
  const approve = useCallback(() => handleBillingAction('approve', rowId), [handleBillingAction, rowId])
  const refuse = useCallback(() => handleBillingAction('refuse', rowId), [handleBillingAction, rowId])

  const refuseDisabled = _.includes(['valid', 'transmit', 'closed', 'to_validate'], _.get(row, 'billing_status'))
  const approveDisabled = _.includes(['valid', 'transmit', 'closed'], _.get(row, 'billing_status'))
  const mergedProps = {
    approveDisabled,
    approve,
    refuseDisabled,
    refuse
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
