import React from 'react'
import { I18n } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row, isSelected, ...props }) => <>
  <TableCell size={'small'}>{_.get(row, 'client_id')}</TableCell>
  <TableCell size={'small'}>{_.get(row, 'name')}</TableCell>
  <TableCell size={'small'}>{_.get(row, 'formula.name')}</TableCell>
  <TableCell size={'small'}><TextCell value={FormatUtils.formatDateBackToFront(_.get(row, 'availableSince'))} /></TableCell>
  <TableCell size={'small'}><TextCell value={FormatUtils.formatDateBackToFront(_.get(row, 'availableUntil'))} /></TableCell>
  <TableCell size={'small'}>
    <TextCell
      value={
        I18n.t(`status.${!!_.get(row, 'deleted')
          ? 'archived'
          : !!_.get(row, 'active')
            ? 'active'
            : 'inactive'}`)
      }
      sx={{
        color: !!_.get(row, 'deleted')
          ? 'red'
          : !!_.get(row, 'active')
            ? 'green'
            : 'orange',
        color: !!_.get(row, 'deleted') ? 'red' : !!_.get(row, 'active') ? 'green' : 'orange',
        fontWeight: 500
      }}
    />
  </TableCell>
</>

export default React.memo(Row)
