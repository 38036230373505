import React, { useMemo } from 'react'
import { hooks } from '@front/volcanion'

import GeoUtils from '@front/volcanion/utils/geo'
import HookUtils from '@front/volcanion/utils/hooks'
import OrderUtils from '@front/volcanion/utils/order'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'
import { Box, Typography, Tooltip, Link } from '@mui/material'

import FormatUtils from '@front/squirtle/utils/format'
import { Download, CalendarMonth } from '@mui/icons-material'
const COLORS = ['white', '#ffe000', '#ff7e00', '#ff0000']

const TitleComponent = ({ color, options }) => <Tooltip title={options}>
  <Box sx={{ width: 1, height: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Box sx={{ width: 24, height: 24, borderRadius: '50%', boxShadow: 3, bgcolor: color, opacity: 0.9 }} />
  </Box>
</Tooltip >

const Row = ({ row }) => {
  const order = _.get(row, 'first_step.order')
  const [{ name: user_name }] = hooks.useUserInfo(order?.do?.user_id, order?.service?.companyservice_id)
  const type = OrderUtils.getOrderType(_.get(order, 'do.auth.application.name'), _.get(order, 'load_type'))
  const priority = _.get(
    _.last(
      _.sortBy(_.flatten([
        _.get(order, 'driver_opts'),
        _.get(order, 'vehicle_opts'),
      ]), 'priority'))
    , 'priority', 0)

  const color = COLORS[priority < 0 ? 0 : priority]

  const options = useMemo(() =>
    _.join(_.flatten([
      _.map(_.get(order, 'driver_opts'), 'name_translated'),
      _.map(_.get(order, 'vehicle_opts'), 'name_translated')
    ]), ', ')
    , [HookUtils.getRecordsWatcher(_.get(order, 'vehicle_opts'), _.get(order, 'driver_opts'))])
  const commercial_formula = !!_.get(order, 'service') ? _.get(order, 'service.contract.formula.name') : _.get(order, 'do.commercial_formula.name')

  return <>
    <TableCell align='center' size='small'>
      <TitleComponent color={color} options={options} />
    </TableCell>
    <TableCell align='center' size='small'> <Typography>{FormatUtils.formatDateBackToFront(_.get(order, 'requestedAt'))} </Typography></TableCell>
    <TableCell align='center' size='small'>
      {_.get(order, 'schedule_type') === 'planned' ? <CalendarMonth color='primary' /> : <Download color='primary' />}
    </TableCell>
    <TableCell align='center' size='small'>
      <Typography
        sx={{
          color: OrderUtils.getColorTheme(type),
          fontWeight: 800,
          fontSize: 'subtitle1.fontSize',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Link sx={{ color: 'inherit' }}
          target='_blank'
          onClick={(e) => {
            window.open(`/order/${_.get(order, 'order_id')}`, '_blank')
            e.stopPropagation()
          }}
        >
          {_.get(order, 'order_client_id')}
        </Link>
      </Typography>
    </TableCell>
    <TableCell align='center' size='small'> <TextCell value={commercial_formula} /></TableCell>
    <TableCell align='center' size='small'> <TextCell value={_.get(order, 'commercial_package.name_translated')} /></TableCell>
    <TableCell align='center' size='small'>
      <TextCell value={GeoUtils.format(_.get(order, 'source'))} />
      <TextCell value={GeoUtils.format(_.get(order, 'destination'))} />
    </TableCell>
    <TableCell align='center' size='small'><TextCell value={_.get(order, 'channel.name')} /></TableCell>
    <TableCell align='center' size='small'><TextCell value={user_name} /></TableCell>
    <TableCell align='center' size='small'><TextCell value={(_.get(row, 'orders') || []).length} /></TableCell>
  </>
}

export default React.memo(Row)
