import React, { useState, useCallback } from 'react'
import { hooks, I18n, CDNImage } from '@front/volcanion'

import { Box, Fade, IconButton, Button, Zoom } from '@mui/material'
import { TextComponent } from '@front/squirtle'

import { Visibility, VisibilityOff } from '@mui/icons-material/'

const AuthenticationPage = (props) => {
  const { openNotification } = hooks.useNotification()
  const [username, onUsernameChange] = useState()
  const [password, onPasswordChange] = useState()
  const [displayPassword, setDisplayPassword] = useState(false)
  const [, signin] = hooks.useAuthFunction('signin')

  const handleSubmit = useCallback(async () => {
    try {
      await signin({ username, password })
    }
    catch (err) {
      if (_.includes(['PASSWORD_INCORRECT', 'RECORD_NOTFOUND'], err.getCode())) {
        openNotification(I18n.t('login.error'), { variant: 'error' })
      } else
        openNotification(I18n.t('error.unknow'), { variant: 'error' })
    }
  }, [openNotification, username, password, signin])

  hooks.useGlobalKeyListener(handleSubmit, ['Enter'], { disabled: !username || !password })

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(https://${hooks.useRelayConstant('CDN_HOST')}/assets/v2/fwa/images/bitmap.png)`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'bottom'
    }}>
      <Box sx={{ width: 1 / 4, display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: 6, borderRadius: 1, p: 1 }}>
        <Fade in timeout={2000}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', pl: 4, pr: 4 }}>
            <Box my={3}>
              <CDNImage name='logo' style={{ maxHeight: 30 }} />
            </Box>
            <Box my={1}>
              <TextComponent
                label={I18n.t('login.label')}
                name='username'
                type='text'
                autoFocus
                value={username}
                onChange={onUsernameChange}
                InputProps={{ style: { fontSize: 18 } }}
                InputLabelProps={{ style: { fontSize: 18 } }}
              />
            </Box>
            <Box my={1}>
              <TextComponent
                label={I18n.t('password.label', { count: 1 })}
                name='password'
                type={!!displayPassword ? 'text' : 'password'}
                value={password}
                onChange={onPasswordChange}
                InputProps={{
                  style: { fontSize: 18 },
                  endAdornment: <IconButton onClick={() => setDisplayPassword(!displayPassword)}>
                    {!!displayPassword ? <VisibilityOff color='error' /> : <Visibility color='primary' />}
                  </IconButton>
                }}
                InputLabelProps={{ style: { fontSize: 18 } }}
              />
            </Box>
            <Box my={2}>
              <Zoom in >
                <Box display='flex' direction='row' justifyContent='center' alignItems='center' p={2}>
                  <Button color="primary" onClick={handleSubmit} variant={'outlined'}>
                    {I18n.t('action.validate')}
                  </Button>
                </Box>
              </Zoom>
            </Box>
          </Box>


        </Fade>
      </Box>
    </Box>
  )
}

export default React.memo(AuthenticationPage)
