class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        status: ['active', 'suspended', 'deleted'],
        ...filter
      }
    }
  }
  static filterToStateHandler(company) {
    return function filterToState(state) {
      return { company }
    }
  }
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      const { companycontract_id, ...rest } = values

      return _.merge({},
        rest,
        !!companycontract_id ? {
          or: [
            { name: { startsWith: companycontract_id } },
            { client_id: { startsWith: companycontract_id } }
          ]
        } : {},
        { status: _.map(_.get(rest, 'status'), elem => ({ name: elem })) },
        { company: _.get(state, 'company') }
      )
    }
  }
}

export default Callbacks
